<template>
  <list-condition-template ref="table" :rows="rows" :total="total" @page-info-change="handlePageInfoChange" :index-column="true">
    <template #condition>
      <el-form-item>
        <el-input placeholder="名称" v-model="listQuery.keyword" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="filter-item" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleFilter">搜索</el-button>
      </el-form-item>
      <el-row>
        <el-button class="filter-item" @click="handleCreate" type="success" size="mini" icon="el-icon-plus" v-waves>新建</el-button>
      </el-row>
    </template>
    <template #columns> <!--slot-scope="columns"-->
      <el-table-column min-width="150px" label="名称" align="center" sortable="custom" property="name">
        <template #default="scope">
          <span>{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="150px" label="描述" align="left" header-align="center">
        <template #default="scope">
          <span>{{scope.row.desc}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="240px" class-name="fixed-width">
        <template #default="scope">
          <el-button type="primary" size="mini" @click="handleUpdate(scope.row)" v-waves>编辑</el-button>
          <el-button type="success" size="mini" @click="handlePermission(scope.row)" v-waves>权限配置</el-button>
          <el-button type="danger" size="mini" @click="DeleteRole(scope.row)" v-waves>删除</el-button>
        </template>
      </el-table-column>
    </template>
  </list-condition-template>
</template>

<script>
import AdminRoleApi from '@/apis/role/role'
import waves from '@/directive/waves' // 水波纹指令
import listConditionTemplate from '@/components/templates/listConditionTemplate'
// import { log } from 'util'
// import _ from 'underscore'

export default {
  directives: {
    waves
  },
  components: {
    listConditionTemplate
  },
  data () {
    return {
      rows: null,
      total: 0,
      listQuery: {
        keyword: null
      }
    }
  },
  created () {
    this.getList()
  },
  methods: {
    /**
     * 加载数据
     * @param {*} pageInfo 分页信息
     */
    getList (pageInfo) {
      AdminRoleApi.getQuery({ pageInfo: pageInfo, condition: { ...this.listQuery } }).then(response => {
        this.rows = response.data.content
        this.total = response.data.totalElements
      })
    },
    /**
     * 排序字段变更处理
     * @param {*} pageInfo 分页信息
     */
    handlePageInfoChange (pageInfo) {
      this.getList(pageInfo)
    },
    /**
     * 搜索条件变更处理
     */
    handleFilter () {
      this.getList(this.listQuery.pageInfo)
    },
    /**
     * 新增按钮点击处理
     */
    handleCreate () {
      this.$router.pushTo(this.$router.routesConfig.roleNew)
    },
    /**
     * 修改按钮点击处理
     * @param {*} row 参数
     */
    handleUpdate (row) {
      this.$router.pushTo(this.$router.routesConfig.roleEdit, row.id )
    },
    /**
     * 删除按钮点击处理
     * @param {*} row 参数
     */
    DeleteRole (row) {
      this.$confirm('删除后角色对应功能会丢失,管理员与该角色的关联关系会被删除，确定要删除该角色吗？', '警告', {
        type: 'warning'
      }).then(() => {
        AdminRoleApi.delete(row.id).then(res => {
          if (res.data) {
            this.$message.success({
              title: '操作成功',
              message: '删除角色成功',
              // duration: 0,
              position: 'bottom-right'
            })
            this.handleFilter()
          } else {
            this.$message.error({
              message: '删除角色失败，请稍后重试！！！',
              duration: 0,
              position: 'bottom-right'
            })
          }
        })
      })
    },
    /**
     * 配置权限
     * @param  {Object} row 数据
     */
    handlePermission (row) {
      this.$router.pushTo(this.$router.routesConfig.rolePermissions, row.id)
    }
  }
}
</script>
